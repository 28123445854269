// PPA Variables
@import "../../../../assets/scss/Variables";

@mixin missing-fields-border {
  border: 2px solid #FE5353;
  background: rgba(#FE5353, .02)
}

// PPA Page Root
#ppa {
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #ECEEF1 100%) 0 0 no-repeat padding-box;

  /*******************/
  /***** GENERAL *****/
  /*******************/

  @keyframes expandWidth {
    0% { width: 0; }
    100% { width: 100%; }
  }

  @keyframes expandFromLeft {
    0% { width: 0; }
    100% { width: 6px; }
  }

  input {
    font-size: 14px;
    padding: 10px 7px;

    @media screen and (min-width: 900px) {
      font-size: inherit;
      padding: 10px 14px;
    }
  }

  & > div {
    position: relative;

    &.container { align-items: normal }

    .content{
      position: relative;
      & > :before {
        content: "";
        height: 1px;
        width: 100%;
        background: $dark;
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: .1;
      }
    }
    h2 {
      line-height: 3rem;

      span {
        position: relative;
        width: fit-content;
        display: flex;

        &:after {
          display: block;
          content: "";
          height: 10px;
          width: 100%;
          max-width: 140px;
          background: $blue-light-title;
          position: absolute;
          bottom: -10px;
          left: 0;
          clip-path: polygon(0 4%, 100% 40%, 100% 100%, 1% 100%);

          @media screen and (min-width: 1200px) {
            max-width: 180px;
            bottom: -12px;
          }
        }
      }
    }
  }

  // Buttons
  .button {
    display: flex;
    justify-content: center;
    gap: 8px;
    border-radius: 50px;
    border: 1px solid #DCDCDC;
    padding: 6px 14px;
    font-size: 14px;
    background-color: #F0F0F0;
    font-weight: 400;
    min-width: 86px;
    opacity: 1;

    svg { font-size: 20px; }

    &.back { color: #272727; }
    &.next {
      color: #ffffff;
      background-color:#3380FF;
      &:hover { background-color: #3383BB}

      &[disabled] { opacity: 0.3; transition: opacity 1s ease; }
    }

    &.add { color: #272727; }
    &.remove {
      color: #ffffff;
      background-color:#3380FF;
      &:hover { background-color: #3383BB}

      &[disabled] { opacity: 0.3; transition: opacity 1s ease; }
    }
    
    @media screen and (min-width: 1200px) {
      & {
          padding: 12px 30px;
          font-size: 16px;
          min-width: 136px;
        }
    }
  }

  // ScrollTop Button
  .scroll-top { align-self: center; margin: 0; }

  .ppa-choice-stepper {
    .MuiStepIcon-text { font-size: .8rem; }
  }


  // PPA Form Root
  .ppa-form {

    // Card Item
    .card-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #f4f4f5;
      width: 100%;
      padding: 10px 4px 24px;
      min-height: 100px;
      position: relative;
      color: $grey-light-border;
      margin: 0;
      font-weight: 600;

      @media screen and (min-width: 1200px) {
        max-width: 220px;
        &.external { max-width: unset; }
      }

      @media screen and (min-width: 1200px) {
        & { padding: 4px; }
      }

      .radio-item { position: absolute; top: 0; right: 2px; }

      // Locked/Disabled Item
      &.locked {
        color: $grey-light-text;
        background-color: rgba($grey-light-text, .08);

        button {
          margin: 0 0 0 auto;
          position: absolute;
          right: 0;
          top: 2px;
          width: 100%;
          height: 100%;
          color: $grey-light-text;
          z-index: 20;

          &:hover { background: none }

          .radio-item { top: -4px; right: -2px; }
        }
      }

      span.MuiTypography-root { max-width: 76%; }
      .default-tag {
        font-size: 10px;
        width: fit-content;
        color: white;
        padding: 1px 4px;
        position: absolute;
        bottom: 3px;
        right: 3px;
      }
    }

  } // .ppa-form end

}


// SELECT DROPDOWNS (Classic & Preload Services)
.MuiListSubheader-root {
  &.preload-subheader {
      background: rgba(118, 137, 250, 1);
      color: #FFFFFF;
      font-weight: 600;
      padding: 0 16px;
  }
}

.MuiButtonBase-root {
  &.select-subheader {
    background: rgba(118, 137, 250, 1);
    color: #FFFFFF;
    font-weight: 600;
    padding: 6px 16px;
  }

  &.select-items, &.preload-items {
    font-size: .9rem;
    padding: 10px 16px 10px 32px;
    position: relative;
  }
}


