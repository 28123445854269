// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;


//Import reset
//@import "Reset";
@import "Variables";
@import "Header";
@import "Brand";
@import "Home";
@import "About";
@import "Form";
@import "ChangeLog";
@import "DataGrid";
@import "Toaster";

@import "../../features/admin/assets/admin.scss";
@import "../../features/news/assets/scss/News";
@import "../../features/catalog/assets/scss/Catalog";
@import "../../features/installation/assets/scss/Installation";
@import "../../features/network/assets/scss/Network";
@import "../../features/ppa/assets/scss/Ppa";
@import "../../features/auth/assets/scss/Auth";
@import "../../components/reusableform/assets/scss/Forms";

@import url("https://cdn.svar.dev/fonts/wxi/wx-icons.css");

/*
* Google fonts
**/

@font-face {
  font-family: "Poppins";
  src: url("../webfonts/Poppins-Regular.woff") format("woff"), /* Moderne Browsers */
  url("../webfonts/Poppins-Regular.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../webfonts/Poppins-Medium.woff") format("woff"), /* Moderne Browsers */
  url("../webfonts/Poppins-Medium.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("../webfonts/Poppins-Bold.woff") format("woff"), /* Moderne Browsers */
  url("../webfonts/Poppins-Bold.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: bold;
}

@font-face {
  font-family: "Lato";
  src: url("../webfonts/Lato-Regular.woff") format("woff"), /* Moderne Browsers */
  url("../webfonts/Lato-Regular.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
}

@font-face {
  font-family: "Lato";
  src: url("../webfonts/Lato-Bold.woff") format("woff"), /* Moderne Browsers */
  url("../webfonts/Lato-Bold.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: bold;
}
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

html,
body {
  font-family: Lato, 'sans-serif';
  background:#ffffff;
  color: $dark;
}
#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  main{
    display: flex;
    flex-flow: column;

    flex-grow: 1;
  }
}

.container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-width: 1600px;
  width: 100%;
}
.wrapper{
  width: 90%;
}
.fontfamilly{
  font-family: Poppins, sans-serif;
}
h1 { @apply text-4xl font-bold }
h2 { @apply text-3xl font-extrabold font-poppins }
h3 { @apply lg:text-xl text-lg font-medium font-poppins }

.title { @apply lg:text-7xl text-4xl }

#section{
  &>div{
    position: relative;
    .img-box{
      height: 700px;
      figure{
        height: 700px;
        overflow: hidden;
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 25px;
        }
      }
    }
  }
}
.before-image{
  position:relative;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    height: 100vh;
    width: 250px;
    background: linear-gradient(90deg, #060B32, transparent );
  }
}

.scrollNone::-webkit-scrollbar {
  display: none;  /* Masque la barre de défilement par défaut dans Chrome, Safari, Opera */
}

.searchBar-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.26);
  padding: 1.5rem 1rem;
}
.searchBar-icon {
  color: rgba(0, 0, 0, 0.26);
  margin-right: 2rem;
}

.searchBar-wrap input {
  font-size: 1.5rem;
  outline: none;
  border: none;
  width: 100%;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  background-color: transparent !important;
  color: #fafafa !important;
  transition: none;
  box-shadow: none !important;
}

//Swiper
.swiper-button-prev, .swiper-button-next{
  color: $dark !important;
  //top: 15px !important;

  border: 1px solid $dark !important;
  border-radius: 50%;
  height: 35px !important;
  width: 35px !important;
  &:after{
    font-size: .945rem !important;
  }
}
//.swiper-button-prev{
//  margin-left: -25px;
//}
//.swiper-button-next{
//  margin-right: -25px;
//}

.swiper {
  max-width: 1400px !important;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// Map
.leaflet-popup{
  position: relative;
  .leaflet-popup-content-wrapper{
    .leaflet-popup-content{
      padding: 10px !important;
      margin: 0;
      width: 300px !important;
    }
  }

  .leaflet-popup-close-button{
    background: $light !important;
    border-radius: 50%;
    height: 35px !important;
    width: 35px !important;

    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute !important;
    right:15px !important;
    top:15px !important;
    span{
      font-size: 1.5em;
      color: $dark !important;
    }
  }
}

//Accord Basic
.infos ul>li::marker{
  font-size: .7rem;
  color: $primary;
}

//loader
@-webkit-keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes honeycomb {
  0%,
  20%,
  80%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  30%,
  70% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.honeycomb {
  height: 24px;
  position: relative;
  width: 24px;
}

.honeycomb div {
  -webkit-animation: honeycomb 2.1s infinite backwards;
  animation: honeycomb 2.1s infinite backwards;
  background: $primary;
  height: 12px;
  margin-top: 6px;
  position: absolute;
  width: 24px;
}

.honeycomb div:after, .honeycomb div:before {
  content: '';
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
}

.honeycomb div:after {
  top: -6px;
  border-bottom: 6px solid $primary;
}

.honeycomb div:before {
  bottom: -6px;
  border-top: 6px solid $primary;
}

.honeycomb div:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  left: -28px;
  top: 0;
}

.honeycomb div:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
  left: -14px;
  top: 22px;
}

.honeycomb div:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
  left: 14px;
  top: 22px;
}

.honeycomb div:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
  left: 28px;
  top: 0;
}

.honeycomb div:nth-child(5) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  left: 14px;
  top: -22px;
}

.honeycomb div:nth-child(6) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  left: -14px;
  top: -22px;
}

.honeycomb div:nth-child(7) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  left: 0;
  top: 0;
}

@media screen and (max-width: 830px) {

  .swiper-button-prev{
    margin-left: 25px;
    top: 75px !important;
  }
  .swiper-button-next{
    margin-right: 25px;
    top: 75px !important;
  }

//  PLatformList

  .platformList{
    position: absolute;
    top: 120px;
    flex-direction: column;
    .proPlat{
      display: flex;
      align-items: center;
      justify-content: center;

      gap: 10px;
    }
  }

  // Section comp
  #section{
    .content{
      flex-wrap: wrap;
      h1{
        max-width: 100%;
      }
    }
    div{
      .img-box{
        height: 450px;
        figure{
          height: 450px;
        }
      }
    }

  }
}

.dashboard-calendar-eventType-resource{
  background-color: #11A428;
}

.dashboard-calendar-eventType-personnal-task{
  background-color: #FF6B6B ;
}

.dashboard-calendar-eventType-project{
  background-color: #F3B06B;
}

.dashboard-calendar-eventType-ticket{
  background-color: #BBC4FE;
}

.label-oblique {
  transform: skewX(60deg);
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  width: 40px;
  overflow: hidden;
  clip-path: polygon(0px 0px, 360% 0px, 115% 47%, -11px 80%);
}

.required:after {
  content: " *";
  color: rgba(255, 0, 0, 0.5);
}

::-webkit-scrollbar{
  width: 4px;
  height: 7px;
  background: rgba($primary, .1);
}
::-webkit-scrollbar-thumb{
  background: rgba($dark, .2);
  border-radius: 10px;
}

@media only screen and (max-width : 1024px) {
  .swiper-button-prev, .swiper-button-next{
    top: 0 !important;
    display: none !important;
  }
  .swiper {
    max-width: 100% !important;
    height: 100%;
    overflow-y: auto !important;
  }
}