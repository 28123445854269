// PPA Variables
@import "/src/core/assets/scss/Variables";


@keyframes expandHeight {
  0% { height: 0; }
  100% { height: 100%; }
}

/*** Table Header Management ***/
.list-type-forms {

  thead { border-bottom: 1px solid rgba(0, 0, 0, 0.06); }

  // Always display the first header
  & > div:first-of-type {
    thead { display: table-header-group }
  }

  & > div:not(:first-of-type) {
    // Hide other headers
    thead {
      display: none;

      &.show-header-labels {
        display: table-header-group;
        position: absolute;
        top: 2px;
        z-index: 2;
        max-height: 30px;
        animation: expandHeight .2s ease-in-out;
        border: none;

        .cell { font-size: 0.8rem; background: rgba(51, 128, 255, 1); color: white; position: relative; top: -10px; }
      }
    }
  }


  // Edition Mode :
  .list-type-saved-mode[aria-expanded="true"] {

    // Display header for current...
    thead { display: table-header-group; }

    //...and the next one
    & + div {
      thead { display: table-header-group; }
    }

  }

}



#list-type-form-table, .list-type-form-table  {

  .menu-button {
    padding: 0 5px 0 0;
    margin: 0;
    min-width: unset;
    background: white;
  }

  // HEAD with Fields Labels
  &-head {
    display: flex;

    .cell { font-weight: bold; font-size: 0.9rem; }

    &::-webkit-scrollbar { display: none; }
  }

  // CELLS Management
  .cell {
      padding: 10px 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      flex-grow: 1;
      min-width: 110px;
      text-align: left;

      & > div {
        display: flex;
        flex-flow: row wrap;
        height: 100%;
        width: 90%;
        align-items: center;
      }
  }

  .read-only-fields {
    background-color: transparent;
    font-size: 0.8rem;
    cursor: default;
    position: relative;
    z-index: 2;
    padding: 0 0 0 6px;

    &, // TextField
    & > p, // TextArea
    & input // Select/AutoComplete
    { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }

    &-expanded {
      min-width: 200px;
      min-height: 200px;
      background-color: white;
    }

    input {
      font-size: 0.8rem;

      &[disabled] { color: black; -webkit-text-fill-color: rgba(black, .87); padding: 0 }
    }
    fieldset { border: none; }

    &.select-value { max-width: unset; justify-content: flex-start; }
    &.no-value-table-view { color: #FE5353; }
  }

  &-body {
    display: flex;

    .row {
      height: 60px;
      width: 100%;

      &.input-datas {
        .cell {
          position: relative;
          font-weight: normal;

          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 10px;
            width: 88%;
            height: 70%;
            border: none;
            background-color: rgba(#e5e5e5, .6);
            border-radius: 4px;
          }
        }
      }
    }

    &::-webkit-scrollbar { display: none; }

  }
}